import React from 'react'
import PropTypes from 'prop-types'


class FaqSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: props.initialOpenState }
    this.myRef = React.createRef();
  }
  
  handleClick = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  closeClick = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))

    //親用を取得
    const parent = this.myRef.current

    //親要素までスクロール
    window.scroll({
      top: parent.offsetTop,
      behavior: "smooth"
    });
  }
  
  render() {
    const {
      reverse,
      title,
      questions,
      numbers,
      answers,
    } = this.props

    const { isOpen } = this.state;

    //最初の項目をオープン状態にしておく
    let initialStateA =''
    let initialStateB =''
    
    if (reverse) {
      initialStateA = 'p-faq'
      initialStateB = 'p-faq is-active'
    } else {
      initialStateA = 'p-faq is-active'
      initialStateB = 'p-faq'      
    }

  
    return (
      <section
        ref={this.myRef}
        className={this.state.isOpen ? initialStateA : initialStateB}
      >
        <h2
          className="p-faq__head"
          onClick={() => this.handleClick()}
        >
          {title}
        <span className="p-faq__button" />
        </h2>
        <dl className="p-faq__body">
          {(() => {
            //FAQ部分 ループ処理
            const items = []
            for (let i = 0; i < questions.length; i++) {
              items.push(
                <li className="p-faq__body" key={questions[i]}>
                  <p className="p-faq__title">
                    <span className="p-faq__icon--q">Q.</span>
                    {questions[i]} <span className="p-faq__num">&#40;{numbers[i]}&#41;</span>
                  </p>
                  <p className="p-faq__copy">
                    <span className="p-faq__icon--a">A.</span>
                    {answers[i]}
                  </p>
                </li>)
            }
            return <ul>{items}</ul>
            
          })()}

        </dl>
        <button
          className="p-faq__closeButton"
          onClick={() => this.closeClick()}
        >CLOSE</button>
      </section>
    )
  }

}

FaqSection.propTypes = {
  title: PropTypes.string,
  question:  PropTypes.string,
  number:  PropTypes.number,
  answer:  PropTypes.string,
};

export default FaqSection;