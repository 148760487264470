import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import FaqSection from '../components/faqSection'

export const FaqPageTemplate = ({
  title,
  titleEn,
  helmet,
  content,
}) => {
  return (
    <main className="l-main">
      <div className="l-container">
        {helmet || ''}
        <h1 className="c-pageTitle" data-spacingsp="mb7">
          <span className="c-pageTitle__container">
            <span className="c-pageTitle__textJa">{title}</span>
            <span className="c-pageTitle__textEn">{titleEn}</span>
          </span>
        </h1>
      </div>
      <div className="l-container--wide">
        <ul className="p-breadCrumbs">
          <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
          <li className="p-breadCrumbs__item isCurrent">{title}</li>
        </ul>
      </div>
    
      <div className="u-bg--lightGray" data-spacing="pv8" data-spacingsp="pv2">
        <div className="l-container--spFull">
          <FaqSection
            reverse={true}
            title={content.faq1.title}
            questions={content.faq1.detail.questions}
            numbers={content.faq1.detail.numbers}
            answers={content.faq1.detail.answers}
          />
          <FaqSection
            title={content.faq2.title}
            questions={content.faq2.detail.questions}
            numbers={content.faq2.detail.numbers}
            answers={content.faq2.detail.answers}
          />
          <FaqSection
            title={content.faq3.title}
            questions={content.faq3.detail.questions}
            numbers={content.faq3.detail.numbers}
            answers={content.faq3.detail.answers}
          />
          <FaqSection
            title={content.faq4.title}
            questions={content.faq4.detail.questions}
            numbers={content.faq4.detail.numbers}
            answers={content.faq4.detail.answers}
          />
          <FaqSection
            title={content.faq5.title}
            questions={content.faq5.detail.questions}
            numbers={content.faq5.detail.numbers}
            answers={content.faq5.detail.answers}
          />
        </div>
      </div>
    </main>
  )
}

FaqPageTemplate.propTypes = {
  heading: PropTypes.string,
  headingEn: PropTypes.string,
  title: PropTypes.string,
  titleEn: PropTypes.string,
  description: PropTypes.string,
  helmet: PropTypes.object,
  number: PropTypes.string,
}


const FaqPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  let schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      '@id': "https://arbor.jp",
      url: "https://arbor.jp",
      name: "株式会社アーバ",
      alternateName: "株式会社アーバ",
      address: {
        '@type': 'PostalAddress',
        'postalCode': '372-0012',
        'addressCountry': 'JP',
        'addressRegion': '群馬県',
        'addressLocality': '伊勢崎市',
        'streetAddress': '豊城町2144−3',
      },
      founder: {
        '@type': 'Person',
        'givenName': '直人',
        'familyName': '石丸',
      },
      foundingDate: '2018-1-11',
      description: frontmatter.description,
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          name: "トップページ",
          position: 1,
          item: {
            "@id": "https://arbor.jp",
            "name": "株式会社アーバ",
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            "@id": typeof window !== "undefined" && window.location.href,
            "name": frontmatter.title,
          }
        },
      ],
    },
  ]; 

  return (
    <Layout>
      <FaqPageTemplate
        title={frontmatter.title}
        titleEn={frontmatter.titleEn}
        description={frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | 株式会社アーバ">
            <title>{`${frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${frontmatter.description}`}
            />
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
          </Helmet>
        }
        content={frontmatter.content}
      />
    </Layout>
  )
}

FaqPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default FaqPage

export const FaqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        titleEn
        description
        content{
          faq1{
            title
            detail{
              numbers
              questions
              answers
            }
          }
          faq2{
            title
            detail{
              numbers
              questions
              answers
            }
          }
          faq3{
            title
            detail{
              numbers
              questions
              answers
            }
          }
          faq4{
            title
            detail{
              numbers
              questions
              answers
            }
          }
          faq5{
            title
            detail{
              numbers
              questions
              answers
            }
          }
        }
      }
    }
  }
`
